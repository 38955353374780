import './Thanks.css';

export default function Thanks() {

    return (
        <div id="Thanks">
            <p>Dziękujemy za przesłaną wiadomość!</p>
            <p>Postaramy sie odpowiedzieć tak prędko jak to możliwe &#128578;</p>
        </div>
    )
}